var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOpen)?_c('div',[_c('div',{staticClass:"head-col d-flex justify-content-between align-items-center"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Chi tiết KeyValue ")]),_c('div',[(!_vm.isDetail)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.call_api_ICFV01()}}},[_vm._v(" "+_vm._s(_vm.$t("golf_common_save"))+" ")]):_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.call_api_UCFV01()}}},[_vm._v(" "+_vm._s(_vm.$t("golf_common_update"))+" ")])],1)]),_c('div',{staticClass:"detail-tee"},[_c('validation-observer',{ref:"detailTeeForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('h6',[_vm._v("Thông tin KeyValue")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'KeyCode' + ' *'}},[_c('validation-provider',{attrs:{"rules":"required|max:50","name":'KeyCode',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.ConfigValue.KeyCode),callback:function ($$v) {_vm.$set(_vm.ConfigValue, "KeyCode", $$v)},expression:"ConfigValue.KeyCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3224046144)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'KeyValue' + ' *'}},[(_vm.dataSelectList)?_c('v-select',{attrs:{"label":"Name","options":_vm.dataSelectList,"reduce":function (x) { return x.Id; },"clearable":true,"placeholder":"KeyValue"},model:{value:(_vm.ConfigValue.KeyValue),callback:function ($$v) {_vm.$set(_vm.ConfigValue, "KeyValue", $$v)},expression:"ConfigValue.KeyValue"}}):_c('validation-provider',{attrs:{"rules":"required","name":'KeyValue',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.ConfigValue.KeyValue),callback:function ($$v) {_vm.$set(_vm.ConfigValue, "KeyValue", $$v)},expression:"ConfigValue.KeyValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2623184092)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'LangId' + ' *'}},[_c('validation-provider',{attrs:{"rules":"required","name":'LangId',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.ConfigValue.LangId),callback:function ($$v) {_vm.$set(_vm.ConfigValue, "LangId", $$v)},expression:"ConfigValue.LangId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,596827832)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Seq' + ' *'}},[_c('validation-provider',{attrs:{"rules":"required|max:50","name":'Seq',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.ConfigValue.Seq),callback:function ($$v) {_vm.$set(_vm.ConfigValue, "Seq", $$v)},expression:"ConfigValue.Seq"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,569476743)})],1)],1)],1)],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import DetailConfig from '@/views/modules/configReport/DetailConfig/DetailConfig.vue'
import { config } from '@/api/config-setting'
export default {
    name: 'Config',
    components: {
        DetailConfig
    },
    data() {
        return {
            data: null,
            tabSelect: 0,
            listAllConfigGroup:[],
            listConfigGroup:[],
            totalConfigGroup:[],
            datalanguage: [],
            dataPolicy: [],
            dataBusiness: [],
            dataReport: [],
            dataOther: [],
        }
    },
    created() {
        this.call_api_RCF01()
    },
    watch: {
        tabSelect() {
            console.log(this.tabSelect)
        }
    },
    methods: {
        async call_api_RCF01() {
            // Lấy danh sách config
            await config
              .API_RRCF01()
              .then(res => {
                this.listAllConfigGroup = res.Data.ReportConfig
                this.listAllConfigGroup.forEach((value,i) => {
                  if (!this.listConfigGroup.includes(value.KeyGroup)) {
                    this.listConfigGroup.push(value.KeyGroup)
                    const sum = this.listAllConfigGroup.filter((b) => {
                      return b.KeyGroup == value.KeyGroup
                    }).length
                    this.totalConfigGroup.push({ KeyGroup: value.KeyGroup, Count: sum })
                  }
                })
                // this.totalConfigGroup.forEach((item,index) => {
                //     if(item.KeyGroup == "FRONT_END"){
                //         this.datalanguage.push(item)
                //     }
                //     if(item.KeyGroup == "PAYMENT_POLICY"){
                //         this.dataPolicy.push(item)
                //     }
                //     if(item.KeyGroup == "BOOKING_CONFIG" | item.KeyGroup == "RATE_CONFIG" | item.KeyGroup == "PACKAGE_CONFIG"){
                //         this.dataBusiness.push(item)
                //     }
                //     if(item.KeyGroup == "EMAIL_CONFIRMATION" | item.KeyGroup == "REPORT_CONFIG" | item.KeyGroup == "REPORT_GROUP_CONFIG" | item.KeyGroup == "REPORT_RB" | item.KeyGroup == "REPORT_RCS" | item.KeyGroup == "REPORT_RF" | item.KeyGroup == "EMAIL_ACC_CONFIRMATION"){
                //         this.dataReport.push(item)
                //     }
                //     if(item.KeyGroup !== "FRONT_END" && item.KeyGroup !== "PAYMENT_POLICY" && item.KeyGroup !== "BOOKING_CONFIG" && item.KeyGroup !== "RATE_CONFIG" && item.KeyGroup !== "PACKAGE_CONFIG" && item.KeyGroup !== "EMAIL_CONFIRMATION" && item.KeyGroup !== "EMAIL_CONFIRMATION" && item.KeyGroup !== "REPORT_CONFIG" && item.KeyGroup !== "REPORT_GROUP_CONFIG" && item.KeyGroup !== "REPORT_RB" && item.KeyGroup !== "REPORT_RCS" && item.KeyGroup !== "REPORT_RF" && item.KeyGroup !== "EMAIL_ACC_CONFIRMATION"){
                //         this.dataOther.push(item)
                //     }
                // })
              })
          },
    },
}
import {
  BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BSidebar,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, max,
} from '@validations'
import vSelect from 'vue-select'
import { showResToast } from '@/@core/utils/utils'
import { config } from '@/api/config-setting'

export default {
  name: 'DetailTee',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BSidebar,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
  },
  props: {
    teeId: {
      type: Number,
      required: true,
    },
    KeyValueId: {
      type: Number,
      required: true,
    },
    KeyCode: {
      type: String,
      required: true,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isList: {
      type: Boolean,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      detailTee: {
        Tee: {
          TeeName: null,
          HoleStartID: null,
          TeeLink: [],
        },
      },
      detailKeyValue: null,
      ConfigValue: {
        KeyCode: this.KeyCode,
        KeyValue: null,
        LangId: null,
      },
      dataSelectList: null,
    }
  },
  created() {
    // Dịch vee validate
    if (this.currentLang == 1000000) {
      localize('vi')
    } else {
      localize('en')
    }
    this.call_api_RCFV02(this.KeyValueId)
  },
  watch: {
    KeyValueId(value) {
      this.call_api_RCFV02(value)
    },
    isList() {
      if(this.isList == true) {
        this.dataSelectList = [
          {
            Name: "bar",
            Id: 1
          },
          {
            Name:"Nhà hàng",
            Id:3
          }
        ]
      } else {
        this.dataSelectList = null
      }
    }
  },
  methods: {
    afterUpdateDetailTee(res) {
      this.$emit('afterUpdateDetailTee', res)
    },
    afterDeleteTee(res) {
      this.$emit('afterDeleteTee', res)
    },
    afterUpdateStatusTee(res) {
      this.$emit('afterUpdateStatusTee', res)
    },
    // Thông tin chi tiết Tee
    async call_api_RCFV02(KeyValueId) {
      this.isOpen = true
      const body = {
        ReportConfigValue: {
          Id: KeyValueId,
        },
      }
      await config
        .API_RRCFV02(body)
        .then(res => {
          if (res.Status === '200') {
            this.detailKeyValue = res.Data.ReportConfigValue
            this.ConfigValue = {
              KeyCode: this.KeyCode,
              KeyValue: this.detailKeyValue.KeyValue,
              LangId: this.detailKeyValue.LangId,
              Seq: this.detailKeyValue.Seq
            }
          }
        })
    },
    async call_api_UCFV01() {
      this.ConfigValue.LangId = +this.ConfigValue.LangId
      const body = {
        ReportConfigValue: {
          Id: this.detailKeyValue.Id,
          KeyCode: this.ConfigValue.KeyCode,
          KeyValue: this.ConfigValue.KeyValue,
          LangId: this.ConfigValue.LangId,
          Seq: Number(this.ConfigValue.Seq)
        },
      }
      await config
        .API_URCFV01(body)
        .then(res => {
          this.showResToast(res)
          this.ConfigValue = {
            KeyCode: this.KeyCode,
            KeyValue: null,
            LangId: null,
            Seq: null
          }
          this.afterAddKeyValue(res)
        })
    },
    OpenAddKeyValue() {
      this.reset()
      this.isOpen = true
      this.isDetail = false
    },
    async call_api_ICFV01() {
      this.ConfigValue.LangId = +this.ConfigValue.LangId
      this.ConfigValue.KeyValue = String(this.ConfigValue.KeyValue)
      const body = {
        ReportConfigValue: {
          KeyCode: this.KeyCode,
          KeyValue: String(this.ConfigValue.KeyValue),
          LangId: +this.ConfigValue.LangId,
          Seq: Number(this.ConfigValue.Seq)
        },
      }
      await config
        .API_IRCFV01(body)
        .then(res => {
          this.showResToast(res)
          this.afterAddKeyValue(res)
          this.reset()
        })
    },
    afterAddKeyValue(res) {
      this.$emit('afterAddKeyValue', res)
    },
    afterUpdateKeyValue(res) {
      this.$emit('afterUpdateKeyValue', res)
    },
    reset() {
      this.isDetail = false
      this.ConfigValue = {
        KeyCode: this.KeyCode,
        KeyValue: null,
        LangId: null,
      }
    },
    showResToast,
  },
}

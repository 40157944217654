/* eslint-disable no-unreachable */
import BCardCode from '@core/components/b-card-code'
import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BSidebar, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { config } from '@/api/config-setting'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import DetailKeyValue from '@/views/modules/configReport/DetailKeyValue/DetailKeyValue.vue'
import AddKeyCode from '@/views/modules/configReport/AddKeyCode/AddKeyCode.vue'
const {gzip, ungzip} = require('node-gzip');
export default {
  name: 'ConfigDetail',
  props: [
    'totalConfigGroup'
  ],
  components: {
    DetailKeyValue,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BSidebar,
    BCardCode,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    'delete-dialog': DeleteDialog,
    AddKeyCode
  },
  data() {
    return {
      listAllConfigGroup: [],
      listConfigGroup: [],
      listHotel: [],
      listOptionTee: [],
      listTee: [],
      KeyGroupSelected: null,
      KeyValueSelected: null,
      listHole: [],
      teeOptions: [],
      RemoveData: null,
      currentLang: this.$i18n.locale,
      isLoadingCourse: false,
      listCount: [],
      listKeyCode: [],
      listConfigValue: [],
      KeyCodeSelected: null,
      KeyValueSelected: null,
      keyword: null,
      isOpen: false,
      allKeyCode: null,
      KeyCodeFromList: null,
      IsList: false
    }
  },
  created() {
    this.getKeyCodeFromList()
    // this.getListConfigGroup()
  },
  methods: {
    async call_api_RCFV01(item) {
      this.IsList = false
      this.KeyCodeFromList.forEach(x => {
        if(x.KeyValue.includes(item)){
          this.IsList = true
        }
      })
      this.KeyCodeSelected = item
      const body = {
        KeyCode: item,
      }
      await config.API_RRCFV01(body)
        .then(res => {
          this.listConfigValue = res.Data.ReportConfigValue
        })
    },
    // async getListConfigGroup() {
    //     this.totalConfigGroup = this.data
    // },
    async call_api_RCF02(item, isUpdateTee) {
      this.listConfigValue = []
      this.KeyGroupSelected = item

      const body = {
        KeyGroup: item,
      }
      await config
        .API_RRCF02(body)
        .then(res => {
          if (res) {
            this.listKeyCode = res.Data.ReportConfig
            this.allKeyCode = res.Data.ReportConfig
          }
        })
    },
    afterAddKeyValue() {
      this.call_api_RCFV01(this.KeyCodeSelect)
    },
    OpenAddKeyCode(KeyCode) {
      this.$refs.AddKeyCode.open(this.KeyGroupSelected,KeyCode)
    },
    AfterAddKeyCode() {
      this.call_api_RCF02(this.KeyGroupSelected)
    },
    searchKeyCode() {
      const tmpAllKeyCode = []
      this.allKeyCode.forEach(x => {
        if(x.KeyCode.toUpperCase().includes(this.keyword.toUpperCase())){
          tmpAllKeyCode.push(x)
        }
      })
      this.listKeyCode = tmpAllKeyCode
    },
    async getKeyCodeFromList() {
      const body = {
        KeyCode: "CONFIG_KEYCODE_FROM_LIST",
      }
      await config.API_RRCFV01(body)
        .then(res => {
         this.KeyCodeFromList = res.Data.ReportConfigValue
        })
    },
    async DeteleKeyCode(data) {
      const body = {
        Config: {
          KeyCode: data
        }
      }
      await config.API_DRCF02(body).then(res => {
        this.showResToast(res)
        if(res.Status === "200") {
          this.call_api_RCF02(this.KeyGroupSelected)
        }
      })
    },
    async DeteleKeyValue(KeyValueId) {
      const body = {
        ReportConfigValue: {
          Id: KeyValueId
        }
      }
      await config.API_DRCFV01(body).then(res => {
        this.showResToast(res)
        if(res.Status === "200") {
          this.afterAddKeyValue()
        }
      })
    },
    async exportFileConfig(data) {
      await config.API_RRCF03({
        KeyGroup: [data]
      })
      .then(res => {
        var a = document.createElement("a"); //Create <a>
        a.href = `data:${res.Data.ContentType},` + res.Data.FileContents; //Image Base64 Goes here
        a.download = res.Data.FileDownloadName; //File name Here
        a.click(); //Downloaded file
      })
    },
    async readFile(event) {
      const input = event.target
      const [file] = input.files
      const text = await file.text()
      await config.API_IRCF02({
        ReportConfigs: JSON.parse(atob(text))
      }).then(res => {
        this.getKeyCodeFromList()
      })
    },
    triggerClickChooFiles() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.click();
    },
  },
}
